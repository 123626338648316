<template>
  <div class="columns is-multiline productDetail is-centered">
    <modal-image
      v-if="imageModal"
      :close="closeModal"
      :image="image"
    />
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard is-flex">
      <div>
        {{detail.name}} - <span class="tag is-info">{{detail.statusName}}</span>
      </div>
      <div>
        {{detail.name}} - <span class="tag is-info">{{detail.statusName}}</span>
      </div>
      <div class="has-text-right" v-if="detail.status === PENDING_STATUS">
        <label class="button-edit" @click="editProduct">
          <i class="material-icons click">edit</i>
        </label>
      </div>
    </div>
    <div class="column is-12 separator"></div>
    <div class="column is-12">
      <div class="table-container">
        <table class="table is-bordered is-striped is-narrow is-fullwidth is-hoverable" v-if="detail.ventas.length > 0">
          <thead>
            <tr>
              <th>
                ID transacción
              </th>
              <th>
                Fecha de transacción
              </th>
              <th>
                Comprador
              </th>
              <th>
                Confirmado por el vendedor
              </th>
              <th>
                Confirmado por el comprador
              </th>
              <th>
                Tipo de entrega
              </th>
              <th>
                Estatus
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(venta, index) in detail.ventas" :key="index">
              <td>{{venta.id}}</td>
              <td>{{formatDateTimestamp(venta.createdAt, 'YYYY-MM-DD')}}</td>
              <td class="userDetail">
                <img :src="venta.userImage">
                {{venta.username}}
              </td>
              <td class="has-text-centered">
                {{venta.buyer_confirm === null ? '-' : venta.buyer_confirm ? 'Recibido' : 'No recibido' }}
              </td>
              <td class="has-text-centered">
                {{venta.seller_confirm === null ? '-' : venta.seller_confirm ? 'Entregado' : 'No entregado' }}
              </td>
              <td>{{venta.deliveryMode}}</td>
              <td>{{venta.statusLabel}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../../mixin/validateDate'

export default {
  mixins: [mixins],
  props: {
    detail: {
      type: Object,
      required: true
    },
    editProduct: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      PENDING_STATUS: 1,
      imageModal: false,
      image: null
    }
  },
  methods: {
    formatMoney (amount) {
      return parseInt(amount).toLocaleString('en', { currency: 'USD' })
    },
    showImageModal (image) {
      this.image = image
      this.imageModal = true
    },
    closeModal () {
      this.imageModal = false
    },
    setCategoriesList (categories) {
      return categories.reduce((accum, category, index) => {
        const coma = index === 0 ? '' : ', '
        return `${accum}${coma}${category.name}`
      }, '')
    }
  }
}
</script>

<style scoped lang="scss">
 .productImage {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.09);
    }
  }
  strong {
    font-size: 14px;
    color: var(--secondary-color);
  }
  .productDetail {
    font-size: 12px;
  }
  .click {
    cursor: pointer;
  }
  .title-image {
    font-size: 14px;
    color: #354676;
    font-weight: 500;
    padding-bottom: 5px;
    margin-bottom: 10px;
    &.main {
      color: #cd3d27;
      font-weight: 600;
    }
  }
  .is-portada {
    border: 1px solid var(--primary-color);
    padding: 5px;
  }
  .label-table {
    font-weight: 700;
    text-align: right;
  }
  .button-edit {
    margin-right: 5px;
    &:hover {
      color: #cd3d27
    }
  }
  .is-flex {
    flex-direction: row;
    justify-content: space-between;
  }
  .userDetail {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;

    img {
      width: 35px;
      margin-right: 10px;
    }
  }
</style>
