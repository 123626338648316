import moment from 'moment'

export default {
  methods: {
    formatDate  (date, format = 'YYYY-MM-DD') {
      return !date ? 'Sin fecha' : moment(parseInt(date)).locale('es').format(format)
    },
    formatDateTimestamp (date, format = 'YYYY-MM-DD') {
      return !date ? 'Sin fecha' : moment(date).locale('es').format(format)
    },
    formatDateTimestampHour (date, format = 'HH:mm:ss') {
      return !date ? 'Sin hora' : moment(date).locale('es').format(format)
    },
    numberFormat (amount = 0) {
      return new Intl.NumberFormat().format(amount)
    },
    formatCurrency (amount = 0, currency = 'MXN') {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency
      }).format(amount)
    }
  }
}
